import React from 'react';

const Footer = () => {
    return (
        <div className="p-1 bg-dark w-100 text-white text-right">
           Copyright @ 2021
        </div>
    );
}

export default Footer;
