import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap'
import YouTube from 'react-youtube';
import '../style/layout.css'

const MediaModal = ({ data, anchor, show, onHide }) => {
    const [selectedMusic, setSelectedMusic] = useState(null);
    const [selectedCentury, setSelectedCentury] = useState("1970s");
    const [player, setPlayer] = useState(null);

    if (!data) {
        return null;
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                setSelectedMusic(null);
                onHide();
            }}
            scrollable={true}
            dialogClassName="modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{ backgroundColor: '#fff', padding: '.5em', fontSize: '1.6em' }}>
                    {data.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedMusic && <div className="responsive-yt" id="yt"><YouTube opts={{
                        width: "450", height: "255",
                        playerVars: { autoplay: 1 }
                    }} onStateChange={(e) => {
                        if (e.data === 0) {
                            const idx = data[selectedCentury].findIndex(music => music.link === selectedMusic);
                            if (idx < data[selectedCentury].length - 1) {
                                setSelectedMusic(data[selectedCentury][idx + 1].link)
                            } else {
                                setSelectedMusic(data[selectedCentury][0].link)
                            }
                        }
                    }}
                        videoId={selectedMusic.split("/")[selectedMusic.split("/").length - 1]} /></div>}
                    <div style={{ padding: '1em', backgroundColor: '#6e0404' }}>
                        <div style={{ padding: '.5em', backgroundColor: '#fff' }}>
                            <div className="media-modal-century-options-container">
                                <div style={{ fontSize: '1.4em' }}>Playlist</div>
                                <div className="media-modal-century-options">
                                    <button style={{ backgroundColor: selectedCentury === '1970s' ? 'yellow' : 'red', border: '2px solid red' }} value="1970s" onClick={e => {
                                        setSelectedCentury(e.target.value)
                                        setSelectedMusic(null);
                                    }}>1970s</button>
                                    <button style={{ backgroundColor: selectedCentury === '1980s' ? 'yellow' : 'red', border: '2px solid red' }} value="1980s" onClick={e => {
                                        setSelectedCentury(e.target.value)
                                        setSelectedMusic(null);
                                    }}>1980s</button>
                                    <button style={{ backgroundColor: selectedCentury === '1990s' ? 'yellow' : 'red', border: '2px solid red' }} value="1990s" onClick={e => {
                                        setSelectedCentury(e.target.value)
                                        setSelectedMusic(null);
                                    }}>1990s</button>
                                </div>
                            </div>
                            <div style={{ margin: '1em 0', maxHeight: '25%', overflow: 'auto' }}>
                                {data[selectedCentury].length ? data[selectedCentury].map(i =>
                                    <p key={`music-item-${i.link}`} style={{ fontSize: '1em', marginBottom: '.25em', fontFamily: 'Digit7', color: selectedMusic === i.link ? 'red' : 'black', cursor: 'pointer' }} onClick={() => {
                                        if (selectedMusic === i.link) {
                                            setSelectedMusic(null)
                                        } else {
                                            setTimeout(() => document.getElementById("yt").scrollIntoView({behavior: 'smooth'}), 250);
                                            setSelectedMusic(i.link)
                                        }
                                    }}>{`${i.title}`}
                                    </p>) : "No content yet"}
                            </div>
                        </div>
                    </div>
                    <br />
                    
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ backgroundColor: 'white', border: 'none', color: 'black', fontSize: '1.3em' }} onClick={() => {
                    setSelectedMusic(null);
                    onHide();
                }}>Close</Button>
            </Modal.Footer>
        </Modal >
    );
}

export default MediaModal;
