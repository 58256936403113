import React from 'react';

const Paragraph = ({ left, title, text, src }) => {
    if (left) {
        return <div className="paragraph-container">
            <div className="paragraph-image-container">
                <img src={src} className="paragraph-image"/>
            </div>
            <div className="paragraph-text">
                <h2>{title}</h2>
                <p>{text}</p>
            </div>
        </div>
    }

    return (
        <div className="paragraph-container">
            <div className="paragraph-text">
                <h2>{title}</h2>
                <p>{text}</p>
            </div>
            <div className="paragraph-image-container">
                <img src={src} className="paragraph-image"/>
            </div>
        </div>
    );
}

export default Paragraph;
