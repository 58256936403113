import React, { useState } from 'react';

const Contact = ({send}) => {
    const [title, setTitle] = useState("");
    const [artist, setArtist] = useState("");
    const [label, setLabel] = useState("");
    const [year, setYear] = useState("");
    const [youtubeLink, setYoutubeLink] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [confirm, setConfirm] = useState("");

    const resetForm = () => {
        setTitle("")
        setArtist("")
        setLabel("")
        setYear("")
        setYoutubeLink("")
        setMessage("")
    }
    
    const validateAndSend = () => {
        if (!title || !artist || !year || !label || !youtubeLink) {
            setError("Please fill all information related to the proposed music!")
            setTimeout(() => setConfirm(""), 7000)
        } else {
            setError("")
            setConfirm("Thanks for contacting us, your message is being delivered!")
            send('service_lybhzv8', 'template_2pwh3cn', {title, artist, label, year, youtubeLink, message});
            resetForm()
            setTimeout(() => setConfirm(""), 7000)
        }
    }

    return (
        <div className="m-4">
            <h2>Contribute music / get in touch</h2>
            <form className="d-flex flex-column">
                <label>Title</label>
                <input value={title} onChange={e => setTitle(e.target.value)}/>
                <label>Artist</label>
                <input value={artist} onChange={e => setArtist(e.target.value)}/>
                <label>Label</label>
                <input value={label} onChange={e => setLabel(e.target.value)}/>
                <label>Year</label>
                <input value={year} onChange={e => setYear(e.target.value)}/>
                <label>Youtube Link</label>
                <input value={youtubeLink} onChange={e => setYoutubeLink(e.target.value)}/>
                <label>Message</label>
                <textarea value={message} onChange={e => setMessage(e.target.value)}/>
                {error ?
                <div class="text-danger">{error}</div> : null}
                {confirm ?
                <div class="text-success">{confirm}</div> : null}
                <input type="submit"  onClick={e => {
                    e.preventDefault();
                    validateAndSend();
                }} className="btn btn-light mt-2 w-60 border" value="Submit">
                </input>
            </form>
        </div>
    );
}

export default Contact;
